import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { apiFactory } from '@/modules/api/services/api-factory';

export class ApiServiceBase {
  protected static axiosInstance: AxiosInstance;

  static setAxiosRequestConfig(config: AxiosRequestConfig): void {
    this.axiosInstance = apiFactory(config.baseURL ?? '', config);
  }

  /**
   * Throws an error if the axios instance is not set
   */
  static throwErrorIfAxiosInstanceNotSet(): void {
    if (!this.axiosInstance) {
      throw new Error('Axios instance not set');
    }
  }
}
