import { QueryMany } from '@directus/sdk';
import { FeaturedApp } from '@/models/featured-app';
import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'featured_apps';

export class FeaturedAppsService extends BaseDirectusService {
  static async fetchAllFeatured(params: QueryMany<unknown> = {}) {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<FeaturedApp[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<FeaturedApp>(TABLE_NAME, {
        ...params,
        fields: '*.*',
      });
      const featured = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, featured);
      return featured;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
