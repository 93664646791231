import { Directus } from '@directus/sdk';
import { DirectusCollections } from '@/models/directus-collections';

export const fileUrl = (fileId: string) => {
  const relativeUrl = `/assets${fileId.startsWith('/') ? fileId : `/${fileId}`}`;

  return `${import.meta.env.VITE_DIRECTUS_URL}${relativeUrl.replace(/\/\//g, '/')}`;
};

export const directusApi = () =>
  new Directus<DirectusCollections>(import.meta.env.VITE_DIRECTUS_URL || '', {
    auth: { staticToken: import.meta.env.VITE_DIRECTUS_API_KEY || '' },
  });
