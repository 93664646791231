import Cookies from 'browser-cookies';
import { IAuthServiceGetApiTokenParams } from '@/modules/api/models/i-auth-service-get-api-token-params';

const TOKEN_COOKIE_NAME = 'JS-AUTH-TOKEN';

export class AuthService {
  /**
   * We have three types of authentication in place.
   * Production: Secure token is attached directly to window by server
   * Cookie: If undefined, token can be read from cookie named `JS-AUTH-TOKEN` (useful from testing)
   * Development: In development mode, token is injected at build time from .env and .env.local.
   */
  public static getApiToken(params?: IAuthServiceGetApiTokenParams): string {
    if (
      typeof process === 'undefined' ||
      // process.client is defined in Nuxt.js
      // @ts-expect-error TODO we should get rid of the process global
      process.client
    ) {
      // is client
      const pageToken = (window as any).PAGE_AUTH_TOKEN;
      if (pageToken !== undefined) {
        return pageToken;
      }
      const apiToken = Cookies.get(TOKEN_COOKIE_NAME);
      if (apiToken === null) {
        if (params?.testToken) {
          return params.testToken;
        }
        return '';
      }
      return apiToken;
    }

    // is server
    return (globalThis as any).PAGE_AUTH_TOKEN || '';
  }
}
