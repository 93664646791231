import { QueryMany } from '@directus/sdk';
import { BaseDirectusService } from '@/services/base-directus-service';
import { PartnershipDeal, PartnershipDealApiResult } from '@/models/partnership/partnership-deal';
import { filterByStatusDeepSingle, filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { QueryOptions } from '@/models/query-options';

const TABLE_NAME = 'partnership_deals';

const simplifyStructure = (partnership: PartnershipDealApiResult) => {
  const cp: PartnershipDeal = {
    ...partnership,
    related_tags: partnership.related_tags.map((entry) => entry.tag_id),
    blog_articles: partnership.blog_articles.map((entry) => entry.ghost_articles_id),
    integrations: partnership.integrations.map((entry) => entry.platforms_id),
  };
  return cp;
};

export class PartnershipService extends BaseDirectusService {
  static async fetchAllPartnerships(skipCache = true, queryOptions: QueryOptions = {}): Promise<PartnershipDeal[]> {
    const cacheKey = 'fetch_partnership_deals';
    const cached = this.getCache<PartnershipDeal[]>(cacheKey);
    if (cached && !skipCache) {
      return cached;
    }

    try {
      const result = await this.getItems<PartnershipDealApiResult>(
        TABLE_NAME,
        {
          fields: '*.*.*',
        },
        queryOptions,
      );
      const partnerships = filterByStatusDeepArray(result.data.map((p) => simplifyStructure(p)));
      this.setCache(cacheKey, partnerships);
      return partnerships;
    } catch (e: any) {
      throw e?.data || e;
    }
  }

  static async fetchPartnership(
    slug: string,
    params: QueryMany<PartnershipDealApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<PartnershipDeal> {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<PartnershipDeal>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<PartnershipDealApiResult>(
        TABLE_NAME,
        {
          fields: '*.*.*',
          filter: { slug: { _eq: slug } },
          ...params,
        },
        queryOptions,
      );
      const partnership = filterByStatusDeepSingle(simplifyStructure(result));
      this.setCache(cacheKey, partnership);
      return partnership;
    } catch (e: any) {
      throw e?.data || e;
    }
  }
}
