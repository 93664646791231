import { QueryMany } from '@directus/sdk';
import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';
import { Testimonial } from '@/models/testimonial';

const TABLE_NAME = 'references';

export class TestimonialsService extends BaseDirectusService {
  static async fetchTestimonials(params: QueryMany<Testimonial> = {}): Promise<Testimonial[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<Testimonial[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<Testimonial>(TABLE_NAME, {
        ...params,
        fields: '*.*',
      });
      const testimonials = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, testimonials);
      return testimonials;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
