import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';
import { TermLandingPage } from '@/models/term-landing-page';

const TABLE_NAME = 'term_landing_page';

export class TermLandingPageService extends BaseDirectusService {
  static async fetchTerm(slug: string): Promise<TermLandingPage> {
    const cacheKey = `${TABLE_NAME}-single-${slug}`;
    const cached = this.getCache<TermLandingPage>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getSingleItem<TermLandingPage>(TABLE_NAME, {
        fields: '*.*.*',
        filter: { slug: { _eq: slug } },
      });
      const term = filterByStatusDeepSingle(result);

      this.setCache(cacheKey, term);
      return term;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
