import { QueryMany } from '@directus/sdk';
import { EmbeddedTweet } from '@/models/embedded-tweet';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'embedded_tweets';

export class EmbeddedTweetsService extends BaseDirectusService {
  static async fetchTweets(params: QueryMany<unknown> = {}): Promise<EmbeddedTweet[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<EmbeddedTweet[]>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getItemsWithStatus<EmbeddedTweet>(TABLE_NAME, {
        ...params,
      });
      this.setCache(cacheKey, result.data);
      return result.data;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
