export type EscapeChars = Record<string, string>;

export type DecodeChars = Record<string, string>;

export const escapeChars: EscapeChars = {
  '¢': 'cent',
  '£': 'pound',
  '¥': 'yen',
  '€': 'euro',
  '©': 'copy',
  '®': 'reg',
  '<': 'lt',
  '>': 'gt',
  '"': 'quot',
  '&': 'amp',
  "'": '#39',
  '{': '#123',
  '}': '#125',
};

export const decodeChars: DecodeChars = Object.entries(escapeChars).reduce(
  (acc, [char, entity]) => ({
    ...acc,
    [`&${entity};`]: char,
  }),
  {},
);

/**
 * Parses limited set of characters into their HTML entity equivalents
 * @param text string to be parsed
 */
export function encodeHtmlEntities(text: string) {
  let regexString = '[';

  for (const key in escapeChars) {
    regexString += key;
  }
  regexString += ']';

  const regex = new RegExp(regexString, 'g');

  return text.replace(regex, (m) => `&${escapeChars[m]};`);
}

/**
 * Parses limited set of HTML encoded entities into actual characters
 * @param text string to be parsed
 */
export function decodeHtmlEntities(text: string) {
  const encodedEntities = Object.keys(decodeChars);
  const regexString = encodedEntities.join('|');
  const regex = new RegExp(regexString, 'g');

  // @ts-expect-error TODO fix type
  return text.replace(regex, (m) => decodeChars[m]);
}
