export class IntercomService {
  public static boot(options: Intercom_.IntercomSettings) {
    if (window.Intercom) {
      window.Intercom('boot', options);
    }
  }

  public static show() {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }

  public static hide() {
    if (window.Intercom) {
      window.Intercom('hide');
    }
  }

  public static update(options: Intercom_.IntercomSettings | Dict) {
    if (window.Intercom) {
      window.Intercom('update', options);
    }
  }

  public static onShow(callback: () => void) {
    if (window.Intercom) {
      window.Intercom('onShow', () => callback());
    }
  }

  public static shutdown() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }
}
