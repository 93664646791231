import { QueryMany } from '@directus/sdk';
import { CompetitorApiResult, Competitor } from '@/models/competitor';
import { CompetitorIntegration } from '@/models/competitor-integration';
import { filterByStatusDeepSingle, filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'competitors';

const simplifyStructure = (competitorResult: CompetitorApiResult): Competitor => {
  const competitor: Competitor = {
    ...competitorResult,
    common_features: [],
    added_features: [],
    faqs: [],
  };
  competitor.added_features = competitorResult.added_features.map((feature) => feature.features_id);
  competitor.common_features = competitorResult.common_features.map((feature) => feature.features_id);
  competitor.faqs = competitorResult.faqs.map((feature) => feature.faq_id);
  return competitor;
};

export class CompetitorsService extends BaseDirectusService {
  static async fetchCompetitor(slug: string, params: QueryMany<unknown> = {}): Promise<Competitor> {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<Competitor>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getSingleItem<CompetitorApiResult>(TABLE_NAME, {
        fields: '*.*.*',
        filter: { slug: { _eq: slug } },
        ...params,
      });
      const competitor = filterByStatusDeepSingle(simplifyStructure(result));
      this.setCache(cacheKey, competitor);
      return competitor;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchCompetitorIntegrations(
    slug: string,
    params: QueryMany<unknown> = {},
  ): Promise<CompetitorIntegration[]> {
    const cacheKey = `competitor_integrations-${slug}`;
    const cache = this.getCache<CompetitorIntegration[]>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getItemsWithStatus<CompetitorIntegration>('competitor_integrations', {
        fields: '*.*.*',
        filter: { competitor: { slug: { _eq: slug } } },
        ...params,
      });
      const integrations = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, integrations);
      return integrations;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
