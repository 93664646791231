import { QueryMany } from '@directus/sdk';
import { TestimonialSlider } from '@/models/testimonial-slider';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { QueryOptions } from '@/models/query-options';

const TABLE_NAME = 'testimonials';

export class TestimonialService extends BaseDirectusService {
  static async fetchTestimonial(
    params: QueryMany<TestimonialSlider> = {},
    queryOptions: QueryOptions = {},
  ): Promise<TestimonialSlider[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<TestimonialSlider[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<TestimonialSlider>(
        TABLE_NAME,
        {
          ...params,
          fields: '*.*.*',
        },
        queryOptions,
      );
      const testimonial = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, testimonial);
      return testimonial;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
