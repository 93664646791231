import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';
import { Persona } from '@/models/persona';

const TABLE_NAME = 'personas';

export class PersonasService extends BaseDirectusService {
  static async fetchPersona(slug: string, skipCache = false): Promise<Persona> {
    const cacheKey = `${TABLE_NAME}-single-${slug}`;
    const cached = this.getCache<Persona>(cacheKey);
    if (cached && !skipCache) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<Persona>(TABLE_NAME, {
        fields: '*.*.*, docs.documentation_id.*.*',
        filter: { slug: { _eq: slug } },
      });
      if (!result || result.data.length === 0) {
        throw new Error(`Persona with slug: ${slug} was not found`);
      }
      // @ts-expect-error TODO fix type
      const product = filterByStatusDeepSingle(result.data[0]);

      if (!skipCache) {
        this.setCache(cacheKey, product);
      }
      // @ts-expect-error TODO fix type
      return product;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
