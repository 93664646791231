/**
 * Returns truncated string of the specifieed length. Also allows to add custom truncation mark.
 * @param str {string} String to be truncated
 * @param length {number} Final length of the string (optional, default 100)
 * @param ending {string} Truncation mark added to the end of the string (optional, default "...")
 * @returns {string}
 */
export function truncate(str: string, length = 100, ending = '...'): string {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
}
