import { QueryMany } from '@directus/sdk';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { ProductSections } from '@/models/product-sections';

const TABLE_NAME = 'product_sections';

export class ProductSectionsService extends BaseDirectusService {
  static async fetchProductsSections(params: QueryMany<ProductSections> = {}): Promise<ProductSections[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<ProductSections[]>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getItemsWithStatus<ProductSections>(TABLE_NAME, {
        fields: '*.*.*',
        ...params,
      });
      const productSection = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, productSection);
      return productSection;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
