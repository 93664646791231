import { QueryMany } from '@directus/sdk';
import { TermsAndConditions } from '@/models/terms-and-conditions';
import { PrivacyPolicy } from '@/models/privacy-policy';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { QueryOptions } from '@/models/query-options';

const TERMS_AND_CONDITIONS = 'terms_and_conditions';
const PRIVACY_POLICY = 'privacy_policy';

export class LocalazyLiabilitiesService extends BaseDirectusService {
  static async fetchTermsAndConditions(
    params: QueryMany<TermsAndConditions> = {},
    queryOptions: QueryOptions = {},
  ): Promise<TermsAndConditions> {
    const cacheKey = TERMS_AND_CONDITIONS;
    const cache = this.getCache<TermsAndConditions>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<TermsAndConditions>(TERMS_AND_CONDITIONS, params, queryOptions);
      const termsAndConditions = filterByStatusDeepSingle(result);
      this.setCache(cacheKey, termsAndConditions);
      return termsAndConditions;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchPrivacyPolicy(
    params: QueryMany<PrivacyPolicy> = {},
    queryOptions: QueryOptions = {},
  ): Promise<PrivacyPolicy> {
    const cacheKey = PRIVACY_POLICY;
    const cache = this.getCache<PrivacyPolicy>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<PrivacyPolicy>(PRIVACY_POLICY, params, queryOptions);
      const privacyPolicy = filterByStatusDeepSingle(result);
      this.setCache(cacheKey, privacyPolicy);
      return privacyPolicy;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
