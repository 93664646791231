import { ApiServiceBase } from '@/modules/api/services/api-service-base';
import { CacheService } from '@/modules/common/services/cache-service';
import { UserBase } from '@/modules/user/models/user-base';

export type GetUserParams = {
  slug: string;
  skipCache?: boolean;
};

export const BASE_PATH = '/user';

export const CACHE_KEY = 'user';

export class UserBaseService extends ApiServiceBase {
  static async getUser(
    params: GetUserParams = {
      slug: '',
      skipCache: false,
    },
  ): Promise<UserBase> {
    try {
      this.throwErrorIfAxiosInstanceNotSet();

      const localSlug = params.slug || '';
      const cached = CacheService.get<UserBase>(`${CACHE_KEY}-get-${localSlug}`);
      if (cached && !params.skipCache) {
        return cached;
      }
      const result = await this.axiosInstance.get(`${BASE_PATH}/${localSlug}`);
      const data = result.data as UserBase;
      CacheService.set(`${CACHE_KEY}-get-${localSlug}`, data);
      return data;
    } catch (e: any) {
      throw e.data || e;
    }
  }
}
