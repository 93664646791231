import { ModeService } from '@/services/mode-service';
import { cloneDeep } from 'lodash-es';
import { ItemStatus } from '@/models/item-status';

function hasOwnProperty<X extends object, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function hasRightStatus<T extends { status: ItemStatus }>(entry: T) {
  return ModeService.isProduction()
    ? entry.status === 'published'
    : entry.status === 'published' || entry.status === 'draft';
}

export function filterByStatus<T extends object>(entries: T[]) {
  return cloneDeep(entries).filter((entry) => (hasOwnProperty(entry, 'status') ? hasRightStatus(entry as any) : true));
}

/**
 * Traverses all arrays in entries and filters out all items that don't have proper status
 */
export function filterByStatusDeepArray<T>(entries: (T & Record<string, any> & { status: ItemStatus })[]) {
  const copy: Record<string, any>[] = cloneDeep(entries);
  const filteredEntries = copy.filter((entry) => {
    if (!entry) {
      return false;
    }

    const doesNotHaveStatus = !Object.prototype.hasOwnProperty.call(entry, 'status');
    return doesNotHaveStatus || hasRightStatus(entry as { status: ItemStatus });
  });

  filteredEntries.forEach((entry) => {
    Object.entries(entry).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        entry[key] = filterByStatusDeepArray(value);
      }
    });
  });

  return filteredEntries as unknown as T[];
}

/**
 * Traverses all arrays in a single entry and filters out all items that don't have proper status
 */
export function filterByStatusDeepSingle<T>(entry: T & Record<string, any> & { status: ItemStatus }) {
  const copy: Record<string, any> = cloneDeep(entry);
  Object.entries(copy).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      copy[key] = filterByStatusDeepArray(value);
    }
  });
  return copy as unknown as T;
}
