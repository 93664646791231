import { Sortable } from '@/models/sortable';

export type SortType = 'asc' | 'desc';

export const sortItems = <T>(items: Sortable[], sortType: SortType = 'asc'): T[] =>
  items
    .sort((a, b) => {
      if (a.sort === null || b.sort === null) {
        return 0;
      }

      if (sortType === 'asc') {
        return a.sort - b.sort;
      }
      return b.sort - a.sort;
    })
    .map((item) => item as unknown as T);
