/* eslint-disable no-console */
import loadScript from 'load-script';
import { PostHog, posthog } from 'posthog-js';
import { CommonOptions } from '@/modules/analytics/models/common-options';
import { CommonTrackData } from '@/modules/analytics/models/common-track-data';
import { EcommerceEventData, GeneralEventData } from '@/modules/analytics/models/track-event-data';
import { PageData } from '@/modules/analytics/models/track-page-data';
import { AnalyticsServiceConfig } from '@/modules/analytics/models/analytics-service-config';
import { IdentityOptions } from '@/modules/analytics/models/identity-options';

export function filterQueryParams(queryParams: Record<string, string>) {
  const whitelist = {
    exact: [
      // order clt intent
      'lang_ids',
      'services',

      // relevant from web
      'share_article',
      'intent',
      'method',
      'integration',

      // relevant register data
      'email',
      'origin',
      'signup',
      'signin',
      'ref',
      'locale',

      // marketing & campaigns
      'type',
      'gclid',
      'source',
      'campaign',

      // other relevant data
      'session_id',
      'search',
      'notification',
      'redirect',
      'logout',
      'fromInvitation',
    ],
    startsWith: ['utm_'],
  };

  const filteredObj: Record<string, string> = {};
  Object.keys(queryParams).forEach((key) => {
    if (whitelist.exact.includes(key)) {
      // @ts-expect-error TODO fix type
      filteredObj[key] = queryParams[key];
    }
    if (whitelist.startsWith.some((item) => key.startsWith(item))) {
      // @ts-expect-error TODO fix type
      filteredObj[key] = queryParams[key];
    }
  });
  return filteredObj;
}

export function addDefaultsToEvent<T extends CommonOptions>(payload: T): T {
  // id from identify is not passed in raw form, e.g. GA needs that for custom dimensions
  const userId =
    typeof window?.analytics?.user === 'function'
      ? ((window.analytics.user() as any).traits || (() => ({})))()?.userId
      : '';
  const orgId =
    typeof window?.analytics?.group === 'function'
      ? ((window.analytics.group() as any).traits || (() => ({})))()?.orgId
      : '';

  const enrichedData: T = {
    ...payload,
    userId: payload.userId ?? userId,
    $groups: {
      segment_group: orgId,
    },
  };

  // Add query strings if they exist
  if (typeof document !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    if (Object.values(queryParams).length > 0) {
      enrichedData.routeQuery = filterQueryParams(queryParams);
      enrichedData.search = window.location.search;
    }
  }

  return enrichedData;
}

export class AnalyticsService {
  public static posthog: PostHog = posthog;

  public static initAnalytics(config: AnalyticsServiceConfig) {
    if (!config.disabled && typeof window !== 'undefined' && config.id) {
      AnalyticsService.attachToWindow(config);
      if (config.skipConsent) {
        window.analytics.load(config.id);
      } else {
        AnalyticsService.initThroughConsentManager(config);
      }
      window.analytics.ready(() => AnalyticsService.initIntegrations(config));
    }
  }

  public static trackCookieConsentAccepted(preferences: any) {
    const eventData: GeneralEventData = {
      category: 'User',
      consentStatus: 'Accepted',
      preferences,
    };
    AnalyticsService.trackEvent('Cookie Consent Accepted', eventData);
  }

  public static trackCookieConsentRejected(preferences: any) {
    const eventData: GeneralEventData = {
      category: 'User',
      consentStatus: 'Rejected',
      preferences,
    };
    AnalyticsService.trackEvent('Cookie Consent Rejected', eventData);
  }

  /**
   * https://developers.google.com/tag-platform/security/guides/consent?sjid=3316744328912694346-EU&consentmode=advanced
   */
  public static trackGoogleAnalyticsEvent(eventName: string, data: any) {
    const googleAnalyticsData = {
      category: 'Marketing',
      action: eventName,
      ad_user_data: data.ad_user_data,
      ad_personalization: data.ad_personalization,
      label: 'UserConsent',
    };

    window.gtag('event', eventName, googleAnalyticsData);
  }

  protected static initThroughConsentManager(config: AnalyticsServiceConfig) {
    AnalyticsService.prepareConsentConfig(config.id);
    AnalyticsService.initConsentManagerWhenReady(!!config.debug);
  }

  public static openConsentManager() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && window.consentManager && window.consentManager.openConsentManager();
  }

  protected static handleAllowAllPreferences() {
    const allPreferences: { destinationPreferences: any; customPreferences: any } = {
      destinationPreferences: {
        'Actions Google Analytic 4': true,
        'Google Analytics': true,
        'Google Tag Manager': true,
        'Hubspot Cloud Mode (actions)': true,
        Intercom: true,
        'LinkedIn Insight Tag': true,
        PostHog: true,
        'Send payment emails (Localazy)': true,
      },
      customPreferences: {
        marketingAndAnalytics: true,
        advertising: true,
        functional: true,
      },
    };

    window.consentManager.preferences.savePreferences({
      destinationPreferences: allPreferences.destinationPreferences,
      customPreferences: allPreferences.customPreferences,
    });

    AnalyticsService.trackCookieConsentAccepted(allPreferences);

    window.analytics.load();

    analytics.ready(() => {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
      });

      AnalyticsService.trackGoogleAnalyticsEvent('Cookie Consent Granted', {
        consentStatus: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
      });
    });
    const consentContainer: HTMLElement | null = document.querySelector('#segment-consent-container');
    if (consentContainer) {
      consentContainer.style.display = 'none';
    }
  }

  protected static handleRejectAllPreferences() {
    const rejectedPreferences: { destinationPreferences: any; customPreferences: any } = {
      destinationPreferences: {
        'Actions Google Analytic 4': false,
        'Google Analytics': false,
        'Google Tag Manager': false,
        'Hubspot Cloud Mode (actions)': false,
        Intercom: false,
        'LinkedIn Insight Tag': false,
        PostHog: false,
        'Send payment emails (Localazy)': false,
      },
      customPreferences: {
        marketingAndAnalytics: false,
        advertising: false,
        functional: false,
      },
    };

    window.consentManager.preferences.savePreferences({
      destinationPreferences: rejectedPreferences.destinationPreferences,
      customPreferences: rejectedPreferences.customPreferences,
    });

    AnalyticsService.trackCookieConsentRejected(rejectedPreferences);

    /**
     * https://segment.com/docs/connections/destinations/catalog/google-ads-gtag/
     */
    analytics.ready(() => {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
      });

      AnalyticsService.trackGoogleAnalyticsEvent('Cookie Consent Denied', {
        consentStatus: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
      });
    });

    const consentContainer: HTMLElement | null = document.querySelector('#segment-consent-container');
    if (consentContainer) {
      consentContainer.style.display = 'none';
    }
  }

  public static identify(userId: string, data: CommonTrackData, options?: IdentityOptions, callback?: () => void) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && window.analytics?.identify(userId, data, options, callback);
  }

  /**
   * Use when logging out a user.
   */
  public static reset() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && window.analytics?.reset();
  }

  /**
   * Clear current group's traits
   * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#clearing-traits
   */
  public static clearGroup() {
    if (typeof window !== 'undefined') {
      const group = window.analytics?.group();
      if (group?.traits) {
        group.traits({});
      }
    }
  }

  public static updateIdentity(data: CommonTrackData, options?: IdentityOptions, callback?: () => void) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && window.analytics?.identify(data, options, callback);
  }

  /**
   * Data are any traits.
   * There are some reserved, specially handled traits such as address, avatar, description
   * @see https://segment.com/docs/connections/spec/group/#traits
   */
  public static group(groupId: string, data: CommonTrackData) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && window.analytics?.group(groupId, data);
  }

  /**
   * Data are any properties.
   * There are some reserved, specially handled properties such as revenue, currency, value
   * @see https://segment.com/docs/connections/spec/track/#properties
   */
  public static trackEvent(
    name: string,
    data: GeneralEventData,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' &&
      window.analytics?.track(name, AnalyticsService.enrichEventData(data), options, callback);
  }

  /**
   * Ensure that analytics has been loaded before calling the passed method
   */
  public static ready(callback: () => void) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && window.analytics?.ready(() => callback());
  }

  /**
   * Data are any properties.
   * There are some reserved, specially handled properties such as revenue, currency, value
   * @see https://segment.com/docs/connections/spec/track/#properties
   */
  public static trackEcommerceEvent(
    name: string,
    data: EcommerceEventData,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' &&
      window.analytics?.track(name, AnalyticsService.enrichEventData(data), options, callback);
  }

  /**
   * @param name Name of the page For example, most sites have a “Signup” page that can be useful to tag,
   * so you can see users as they move through your funnel.
   * @param properties Free-form dictionary of properties of the page, like url and referrer See the
   * {@link https://segment.com/docs/connections/spec/page#properties Properties} field docs for a list of reserved
   * property names.
   */
  public static page(name?: string, properties?: PageData) {
    if (properties?.setContentType) {
      // Setting hubspot content type is not supported through segment
      // @ts-expect-error types

      const hsq = window._hsq || [];
      hsq.push(['setContentType', properties.setContentType]);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && window.analytics?.page(name, properties ?? {});
  }

  protected static enrichEventData(data: CommonTrackData) {
    return addDefaultsToEvent(data);
  }

  protected static prepareConsentConfig(segmentKey: string) {
    window.consentManagerConfig = (exports) => {
      const { React, inEU, preferences, savePreferences } = exports;

      const bannerContent = React.createElement(
        'div',
        null,
        React.createElement('h1', null, 'This website uses cookies'),
        React.createElement('br'),
        `We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.
  We also share information about your use of our site with our social media, advertising and analytics partners
  who may combine it with other information that you’ve provided to them or that they’ve collected from your use of
  their services.`,

        React.createElement('br'),
        React.createElement('br'),

        "If you want to read more about what you agree to and what data our site uses, it's all described in our ",

        React.createElement(
          'a',
          {
            href: '/privacy-policy',
            target: '_blank',
          },
          'Website Data Collection Policy',
        ),
        '.',
        React.createElement('br'),

        React.createElement(
          'div',
          { className: 'flex flex-col sm:flex-row gap-3 mt-6 justify-end' },
          React.createElement(
            'button',
            {
              onClick: () => AnalyticsService.openConsentManager(),
              className: 'bg-white',
            },
            'Edit Preferences',
          ),
          React.createElement(
            'button',
            {
              onClick: () => AnalyticsService.handleAllowAllPreferences(),
            },
            'Allow All',
          ),
        ),
      );
      const preferencesDialogTitle = 'Website Data Collection Preferences';
      const preferencesDialogContent = `We use data collected by cookies and JavaScript libraries to improve your
          browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall
          performance of our site.`;
      const cancelDialogTitle = 'Are you sure you want to cancel?';
      const cancelDialogContent = `Your preferences have not been saved.
        By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.`;

      return {
        container: '#segment-consent-container',
        writeKey: segmentKey,
        shouldRequireConsent: inEU,
        preferences,
        savePreferences,
        bannerContent,
        preferencesDialogTitle,
        preferencesDialogContent,
        cancelDialogTitle,
        cancelDialogContent,
        defaultDestinationBehavior: 'enable',
        implyConsentOnInteraction: false,
      };
    };
  }

  protected static initConsentManagerWhenReady(debugLoadedInstance: boolean) {
    let tries = 0;
    const maxTries = 50;
    const poll = setInterval(() => {
      const containerNotLoaded = !document.getElementById('segment-consent-container');
      tries += 1;
      if (containerNotLoaded) {
        if (tries === maxTries) {
          clearInterval(poll);
        }
        return;
      }
      clearInterval(poll);
      const consentManagerSource = 'https://unpkg.com/@segment/consent-manager@5.8.1/standalone/consent-manager';
      loadScript(consentManagerSource, { async: true }, (error: unknown) => {
        if (error) {
          console.log(error);
        }
        window.analytics.debug(debugLoadedInstance);
      });
    }, 500);
  }

  protected static attachToWindow(config: AnalyticsServiceConfig) {
    if (!config.id?.length) {
      throw new Error('Please enter a Segment.io tracking ID');
    }

    // Create a queue, but don't obliterate an existing one!
    window.analytics = window.analytics || [];
    const { analytics }: any = window;

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) {
      console.warn('initialized');
      return;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'load',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
      'gtag',
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory =
      (methodName: string) =>
      (...args: unknown[]) => {
        if (config.debug === true) {
          if (window.console && console.log) {
            console.log(`[Segment Analytics Debug]: ${methodName} method called with ${args.length} args`);
          }
        }
        args.unshift(methodName);
        analytics.push(args);
        return analytics;
      };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.15.3';

    // For each of our methods, generate a queueing stub.
    analytics.methods.forEach((method: string) => {
      analytics[method] = analytics.factory(method);
    });

    window.analytics.load = () => {
      const analyticsSource = `https://cdn.segment.com/analytics.js/v1/${config.id}/analytics.min.js`;
      loadScript(analyticsSource, (error: unknown) => {
        if (error) {
          console.warn('Ops! Is not possible to load Segment Analytics script');
          return;
        }

        const poll = setInterval(() => {
          if (!window.analytics) {
            return;
          }

          clearInterval(poll);
        }, 100);
      });
    };
  }

  protected static initIntegrations(config: AnalyticsServiceConfig) {
    const { enabledIntegrations } = config;
    if (enabledIntegrations?.posthog && enabledIntegrations.posthog !== 'disabled' && config.posthogConfig) {
      if (config.posthogConfig.key && config.posthogConfig.url) {
        posthog.init(config.posthogConfig.key, {
          api_host: config.posthogConfig.url,
          // You want this false if you are going to use segment's `analytics.page()` for pageviews
          capture_pageview: config.posthogConfig.capture_pageview ?? false,
          segment: window.analytics,
          autocapture: config.posthogConfig.autocapture ?? false,
          disable_session_recording: config.posthogConfig.disable_session_recording ?? true,
          loaded: () => window.analytics.page(),
          ...config.posthogConfig,
        });
        if (enabledIntegrations.posthog === 'debug') {
          posthog.debug();
        }
      }
    }
  }
}
