import { QueryMany } from '@directus/sdk';
import { Homepage, HomepageApiResult } from '@/models/homepage';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';

const TABLE_NAME = 'Homepage';

const simplifyStructure = (entry: HomepageApiResult) => {
  const cp: Homepage = {
    ...entry,
    header_badges: entry.header_badges.map((b) => b.badges_id),
    footer_badges: entry.footer_badges.map((b) => b.badges_id),
    slider_badges: entry.slider_badges.map((b) => b.badges_id),
  };
  return cp;
};

export class HomepageService extends BaseDirectusService {
  static async fetchHomepage(params: QueryMany<HomepageApiResult> = {}) {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<Homepage>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getSingleItem<HomepageApiResult>(TABLE_NAME, {
        fields: '*.*.*',
        ...params,
      });
      const homepage = filterByStatusDeepSingle(simplifyStructure(result));
      this.setCache(cacheKey, homepage);
      return homepage;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
