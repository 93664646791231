import { QueryMany } from '@directus/sdk';
import { BaseDirectusService } from '@/services/base-directus-service';
import { PricingPlanApiResult, PricingPlan } from '@/models/pricing-plan';
import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { QueryOptions } from '@/models/query-options';

const TABLE_NAME = 'pricing_plans';

const simplifyPricingPlanStructure = (plan: PricingPlanApiResult) => {
  const simplifiedPlan: PricingPlan = { ...plan, related_faq: [], core_benefits: [] };
  simplifiedPlan.related_faq = plan.related_faq.map((related) => related.faq_id);
  simplifiedPlan.core_benefits = plan.core_benefits.map((benefit) => benefit.pricing_plans_benefits_id);
  return simplifiedPlan;
};

export class PricingPlansService extends BaseDirectusService {
  static async fetchAllPlans(
    params: QueryMany<PricingPlanApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<PricingPlan[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<PricingPlan[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItems<PricingPlanApiResult>(
        TABLE_NAME,
        {
          fields: ['*.*.*', 'core_benefits.*.*.*'],
          ...params,
        },
        queryOptions,
      );
      const simplifiedPlans = filterByStatusDeepArray(result.data.map((plan) => simplifyPricingPlanStructure(plan)));
      this.setCache(cacheKey, simplifiedPlans);
      return simplifiedPlans;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
