import TinyCache from 'tinycache';

const cache = new TinyCache();

export class CacheService {
  static ttl = 300;

  static get<T>(key: string): T | undefined {
    const result: T | null = cache.get<T>(key);
    if (result === null) {
      // Originally different library was used, which returned undefined
      return undefined;
    }
    return result;
  }

  static set<T>(key: string, value: T) {
    cache.put(key, value, this.ttl);
  }

  static del(key: string | string[]) {
    if (typeof key === 'string') {
      cache.del(key);
    } else {
      key.forEach((k) => cache.del(k));
    }
  }

  static flush(): void {
    cache.clear();
  }

  /**
   * Set global TTL for cached items
   * @param ttl Time to live value in seconds
   */
  static setTtl(ttl: number) {
    cache.ttl(ttl);
  }

  static keys(): string[] {
    return Object.keys(cache?._cache || {});
  }
}
