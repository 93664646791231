import { QueryMany } from '@directus/sdk';
import { buildCacheKey } from '@/functions/build-cache-key';
import { Placeholder } from '@/models/placeholder-model';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'placeholders';

export const prefixSuffix = (placeholders: Placeholder[]) => placeholders.map((p) => ({ ...p, key: `___${p.key}___` }));

export class PlaceholdersService extends BaseDirectusService {
  static async fetchPlaceholders(params: QueryMany<Placeholder> = {}): Promise<Placeholder[]> {
    const cacheKey = buildCacheKey(TABLE_NAME, params);
    const cache = this.getCache<Placeholder[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const { data } = await this.getItems<Placeholder>('placeholders', params);
      if (!data) {
        throw Error('Cannot retrieve placeholders');
      }
      const prefixedSuffixedPlaceholders = prefixSuffix(data);
      this.setCache(cacheKey, prefixedSuffixedPlaceholders);
      return prefixedSuffixedPlaceholders;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
