import { QueryMany } from '@directus/sdk';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { QueryOptions } from '@/models/query-options';
import { GhostArticle, GhostArticleApiResult } from '@/models/ghost-article';

const TABLE_NAME = 'ghost_articles';

const simplifyStructure = (article: GhostArticleApiResult) => {
  const cp: GhostArticle = {
    ...article,
    tags: article.tags?.map((entry) => entry.tag_id) || [],
    internal_tags: article.internal_tags?.map((entry) => entry.ghost_internal_tags_id) || [],
  };
  return cp;
};

export class DirectusBlogService extends BaseDirectusService {
  static async fetchArticle(
    slug: string,
    params: QueryMany<GhostArticleApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<GhostArticle> {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<any>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<GhostArticleApiResult>(
        TABLE_NAME,
        {
          fields: '*.*.*',
          ...params,
        },
        queryOptions,
      );
      const article = filterByStatusDeepSingle(simplifyStructure(result));
      this.setCache(cacheKey, article);
      return article;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchArticles(
    params: QueryMany<GhostArticle>,
    queryOptions: QueryOptions = {},
  ): Promise<GhostArticle[]> {
    try {
      const result = await this.getItemsWithStatus<GhostArticle>(
        TABLE_NAME,
        {
          fields: '*',
          limit: -1,
          ...params,
        },
        queryOptions,
      );
      return result.data;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
