import { QueryMany } from '@directus/sdk';
import { buildCacheKey } from '@/functions/build-cache-key';
import { Integration, IntegrationApiResult } from '@/models/integration';
import { IntegrationDetail, IntegrationDetailApiResult } from '@/models/integration-detail';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepArray, filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { IntegrationTag } from '@/models/integration-tag';
import { IntegrationMethod } from '@/models/integration-method';
import { FaqCategory } from '@/models/faq-category';
import { QueryOptions } from '@/models/query-options';

const TABLE_NAME = 'platforms';

export const simplifyStructure = (integration: {
  tags: { integration_tags_id: IntegrationTag }[];
  faq_categories: { faq_category_id: FaqCategory }[];
  integration_methods: { integration_methods_id: IntegrationMethod }[];
  recommended_methods: { integration_methods_id: IntegrationMethod }[];
}) => {
  const simplifiedIntegration: {
    tags: IntegrationTag[];
    faq_categories: FaqCategory[];
    integration_methods: IntegrationMethod[];
    recommended_methods: IntegrationMethod[];
  } = {
    ...integration,
    tags: [],
    faq_categories: [],
    integration_methods: [],
    recommended_methods: [],
  };
  simplifiedIntegration.tags = integration.tags?.map((tag) => tag.integration_tags_id) || [];
  simplifiedIntegration.faq_categories = integration.faq_categories?.map((category) => category.faq_category_id) || [];
  simplifiedIntegration.integration_methods =
    integration.integration_methods?.map((method) => method.integration_methods_id) || [];
  simplifiedIntegration.recommended_methods =
    integration.recommended_methods?.map((method) => method.integration_methods_id) || [];
  return simplifiedIntegration;
};

export class IntegrationService extends BaseDirectusService {
  static async fetchIntegration(
    slug: string,
    params: QueryMany<IntegrationDetailApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<IntegrationDetail> {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<IntegrationDetail>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<IntegrationDetailApiResult>(
        TABLE_NAME,
        {
          fields: '*.*.*.*',
          filter: { slug: { _eq: slug } },
          ...params,
        },
        queryOptions,
      );
      const integration = filterByStatusDeepSingle(simplifyStructure(result) as IntegrationDetail);
      this.setCache(cacheKey, integration);
      return integration;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchAllIntegrations(
    params: QueryMany<IntegrationApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<Integration[]> {
    // Cache calls with different params under different keys
    const cacheKey = buildCacheKey(TABLE_NAME, params);
    const cache = this.getCache<Integration[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<IntegrationApiResult>(
        TABLE_NAME,
        {
          fields: [
            '*',
            'integration_methods.integration_methods_id.*',
            'recommended_methods.integration_methods_id.*',
            'tags.*.*',
            'primary_tag.*.*',
            'meta_image.*',
            'icon.*',
            'translations.*.*.*',
          ],
          ...params,
        },
        queryOptions,
      );
      const integrations = result.data.map((integration) =>
        filterByStatusDeepSingle(simplifyStructure(integration) as Integration),
      );
      this.setCache(cacheKey, integrations);
      return integrations;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchAllIntegrationTags(
    params: QueryMany<IntegrationTag> = {},
    queryOptions: QueryOptions = {},
  ): Promise<IntegrationTag[]> {
    try {
      const result = await this.getItems<IntegrationTag>(
        'integration_tags',
        {
          fields: ['*.*'],
          ...params,
        },
        queryOptions,
      );
      const integrationTags = filterByStatusDeepArray(result.data);
      return integrationTags;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
