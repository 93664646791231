import { QueryMany, Filter } from '@directus/sdk';
import { buildCacheKey } from '@/functions/build-cache-key';
import { FaqApiResult, Faq } from '@/models/faq';
import { filterByStatusDeepSingle, filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'faq';

type FetchFaqsByRelatedFaqFiltersOptions = {
  categorySlugs?: string[];
  subcategorySlugs?: string[];
};

const simplifyRelatedStructure = (faq: FaqApiResult) => {
  const simplifiedFaq: Faq = {
    ...faq,
    related_terms: [],
    related_docs: [],
    additional_categories: [],
    subcategories: [],
  };
  simplifiedFaq.related_terms = faq.related_terms.map((related) => related.dictionary_id);
  simplifiedFaq.related_docs = faq.related_docs.map((related) => related.documentation_id);
  simplifiedFaq.additional_categories = faq.additional_categories.map((related) => related.faq_category_id);
  // simplifiedFaq.subcategories = faq.subcategories.map(related => related.subcategory_id);

  return simplifiedFaq;
};

export class FaqService extends BaseDirectusService {
  static async fetchFaq(slug: string, params: QueryMany<FaqApiResult> = {}) {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<Faq>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<FaqApiResult>(TABLE_NAME, {
        ...params,
        fields: '*.*.*,related_docs.documentation_id.*.*.*',
        filter: { slug: { _eq: slug } },
      });
      const simplifiedTerm = filterByStatusDeepSingle(simplifyRelatedStructure(result));
      this.setCache(cacheKey, simplifiedTerm);
      return simplifiedTerm;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchFaqsByRelatedFaqFilters(
    relatedFaqTypes: FetchFaqsByRelatedFaqFiltersOptions,
    params: QueryMany<FaqApiResult> = {},
  ) {
    const cacheKey = `${TABLE_NAME}-${JSON.stringify(relatedFaqTypes)}`;
    const cache = this.getCache<FaqApiResult[]>(cacheKey);
    if (cache) {
      return cache;
    }

    const { categorySlugs, subcategorySlugs } = relatedFaqTypes;

    const faqFetchBase = async (filter: Filter<FaqApiResult>) =>
      this.getItems<FaqApiResult>(TABLE_NAME, {
        ...params,
        fields: '*.*.*,related_docs.documentation_id.*.*.*',
        filter,
      });

    try {
      const subCategoriesResults = subcategorySlugs
        ? await Promise.all(
            subcategorySlugs.map((categorySlug) =>
              faqFetchBase({
                // @ts-expect-error TODO fix type
                _or: [{ subcategories: { faq_subcategory_id: { slug: { _eq: categorySlug } } } }],
              }),
            ),
          )
        : [];

      const mainCategoriesResults = categorySlugs
        ? await Promise.all(
            categorySlugs.map((categorySlug) =>
              faqFetchBase({
                _or: [
                  { additional_categories: { faq_category_id: { slug: { _eq: categorySlug } } } },
                  { category: { slug: { _eq: categorySlug } } },
                ],
              }),
            ),
          )
        : [];

      const faqs = [...mainCategoriesResults, ...subCategoriesResults]
        .map((result) => filterByStatusDeepArray(result.data))
        .flat()
        .map((faq) => simplifyRelatedStructure(faq));
      this.setCache(cacheKey, faqs);
      return faqs;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchAllFaqs(params: QueryMany<FaqApiResult> = {}) {
    const cacheKey = buildCacheKey(TABLE_NAME, params);
    const cache = this.getCache<FaqApiResult[]>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getItemsWithStatus<FaqApiResult>(TABLE_NAME, {
        fields: '*.*',
        ...params,
      });
      const faqs = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, faqs);
      return faqs;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
