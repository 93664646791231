import { QueryMany } from '@directus/sdk';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepSingle, filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { IntegrationMethod } from '@/models/integration-method';
import { ZapierIntegrationMethod } from '@/models/zapier-integration-method';
import { QueryOptions } from '@/models/query-options';

const TABLE_NAME = 'integration_methods';
const ZAPIER_TABLE_NAME = 'zapier_integration_methods';

export class IntegrationMethodsService extends BaseDirectusService {
  static async fetchAllIntegrationMethods(
    params: QueryMany<IntegrationMethod> = {},
    queryOptions: QueryOptions = {},
  ): Promise<IntegrationMethod[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<IntegrationMethod[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<IntegrationMethod>(
        TABLE_NAME,
        {
          fields: '*.*',
          limit: -1,
          ...params,
        },
        queryOptions,
      );
      const integrationMethod = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, integrationMethod);
      return integrationMethod;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchIntegrationMethod(
    slug: string,
    params: QueryMany<IntegrationMethod> = {},
    queryOptions: QueryOptions = {},
  ): Promise<IntegrationMethod> {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<IntegrationMethod>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<IntegrationMethod>(
        TABLE_NAME,
        {
          fields: '*',
          filter: { slug: { _eq: slug } },
          ...params,
        },
        queryOptions,
      );
      const integrationMethod = filterByStatusDeepSingle(result);
      this.setCache(cacheKey, integrationMethod);
      return integrationMethod;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchAllZapierIntegrationMethods(
    params: QueryMany<ZapierIntegrationMethod> = {},
    queryOptions: QueryOptions = {},
  ): Promise<ZapierIntegrationMethod[]> {
    const cacheKey = `${ZAPIER_TABLE_NAME}`;
    const cache = this.getCache<ZapierIntegrationMethod[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItems<ZapierIntegrationMethod>(
        ZAPIER_TABLE_NAME,
        {
          fields: '*',
          ...params,
        },
        queryOptions,
      );
      const zapierIntegrationMethods = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, zapierIntegrationMethods);
      return zapierIntegrationMethods;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
