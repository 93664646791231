import { AxiosInstance } from 'axios';
import { GeneralEventData } from '@/modules/analytics/models/track-event-data';
import { CommonTrackData } from '@/modules/analytics/models/common-track-data';
import { apiFactory } from '@/modules/api/services/api-factory';

export type AnalyticsServiceHttpConfig = {
  id: string; // auth key
  debug?: boolean;
  // skipConsent?: boolean;
};

export type TrackEventData = CommonTrackData & {
  userId: string;
  event: string;
  properties: GeneralEventData;
  context?: object;
};

export class AnalyticsServiceHttp {
  protected static config: AnalyticsServiceHttpConfig;

  protected static api: AxiosInstance;

  public static initAnalytics(config: AnalyticsServiceHttpConfig) {
    this.config = config;
    let writeKey: string;
    if (typeof window === 'undefined') {
      writeKey = Buffer.from(this.config.id, 'binary').toString('base64');
    } else {
      writeKey = window.btoa(`${this.config.id}:`);
    }
    this.api = apiFactory('https://api.segment.io/v1', {
      headers: {
        Authorization: `Basic ${writeKey}`,
      },
    });
  }

  public static async identify(
    userId: string,
    data?: CommonTrackData,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) {
    const postData = {
      userId,
      traits: data,
      context: options?.context,
      integrations: options?.integrations,
    };

    await this.api.post('/identify', postData);
    if (typeof callback === 'function') {
      callback();
    }
  }

  public static async trackEvent(
    name: string,
    data: GeneralEventData,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) {
    const userId = (data.userId! || options?.anonymousId) ?? 'anonymous';
    const postData: TrackEventData = {
      userId,
      event: name,
      properties: {
        ...data,
        userId: undefined,
      },
      context: options?.context,
      integrations: options?.integrations,
    };

    await this.api.post('/track', postData);
    if (typeof callback === 'function') {
      callback();
    }
  }
}
