class LanguageService {
  private _language: string;

  private _fallbackLanguages: string[] = [];

  constructor() {
    this._language = '';
    this._fallbackLanguages = ['xxa'];
  }

  get language(): string {
    return this._language;
  }

  get fallbackLanguages(): string[] {
    return this._fallbackLanguages;
  }

  disableLocalization(): void {
    this._language = '';
  }

  changeLocale(language: string): void {
    this._language = language;
  }

  setFallbackLanguages(languages: string[]): void {
    this._fallbackLanguages = languages;
  }
}

export const languageService = new LanguageService();
