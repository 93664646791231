import { QueryMany } from '@directus/sdk';
import { buildCacheKey } from '@/functions/build-cache-key';
import { BaseDirectusService } from '@/services/base-directus-service';
import { Tag } from '@/models/tag';
import { BlogIndexTagApiResult } from '@/models/blog-index-tag';
import { QueryOptions } from '@/models/query-options';

const TABLE_NAME = 'tag';

export class DirectusTagService extends BaseDirectusService {
  static async fetchTag(slug: string, params: QueryMany<Tag> = {}, queryOptions: QueryOptions = {}): Promise<Tag> {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<Tag>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<Tag>(
        TABLE_NAME,
        {
          fields: '*',
          filter: { slug: { _eq: slug } },
          ...params,
        },
        queryOptions,
      );
      this.setCache(cacheKey, result);
      return result;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchAllTags(params: QueryMany<Tag> = {}, queryOptions: QueryOptions = {}): Promise<Tag[]> {
    // Cache calls with different params under different keys
    const cacheKey = buildCacheKey(TABLE_NAME, params);
    const cache = this.getCache<Tag[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<Tag>(
        TABLE_NAME,
        {
          fields: '*',
          ...params,
        },
        queryOptions,
      );
      const tags = result.data;
      this.setCache(cacheKey, tags);
      return tags;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchTagsOnBlogIndexPage(
    params: QueryMany<BlogIndexTagApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<Tag[]> {
    const tableName = 'blog_index_tags';
    const cacheKey = buildCacheKey(tableName, params);
    const cache = this.getCache<Tag[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<BlogIndexTagApiResult>(
        tableName,
        {
          fields: '*.*.*',
          ...params,
        },
        queryOptions,
      );

      const tags = [...result.tags].sort((a, b) => ((a.sort ?? 0) > (b.sort ?? 0) ? 1 : -1)).map((tag) => tag.tag_id);
      this.setCache(cacheKey, tags);
      return tags;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
