import { QueryMany } from '@directus/sdk';
import { BaseDirectusService } from '@/services/base-directus-service';
import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { PersonasTab, PersonasTabApiResult } from '@/models/personas-tab';

const TABLE_NAME = 'personas_tab';

const simplifyStructure = (personasTab: PersonasTabApiResult) => {
  const cp: PersonasTab = {
    ...personasTab,
    roles: personasTab.roles.map((entry) => entry.personas_id),
    industries: personasTab.industries.map((entry) => entry.personas_id),
  };
  return cp;
};

export class PersonasTabService extends BaseDirectusService {
  static async fetchPersonasTab(slug = 'default', params: QueryMany<PersonasTabApiResult> = {}): Promise<PersonasTab> {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cached = this.getCache<PersonasTab>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getSingleItem<PersonasTabApiResult>(TABLE_NAME, {
        fields: '*.*.*',
        filter: { slug: { _eq: slug } },
        ...params,
      });
      if (!result) {
        throw new Error(`Item with slug: ${slug} was not found`);
      }
      const personasTab = filterByStatusDeepSingle(simplifyStructure(result));

      this.setCache(cacheKey, personasTab);
      return personasTab;
    } catch (err: unknown) {
      if (slug !== 'default') {
        return this.fetchPersonasTab('default', params);
      }
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
