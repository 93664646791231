import { QueryMany } from '@directus/sdk';
import { Dictionary, DictionaryApiResult } from '@/models/dictionary';
import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'dictionary';

const simplifyRelatedTermsStructure = (term: DictionaryApiResult) => {
  const simplifiedTerm: Dictionary = { ...term, related_terms: [] };
  simplifiedTerm.related_terms = term.related_terms.map((related) => related.other_term_id);
  return simplifiedTerm;
};

export class DictionaryService extends BaseDirectusService {
  static async fetchDictionary(params: QueryMany<Dictionary> = {}) {
    const cacheKey = `${TABLE_NAME}-all`;
    const cache = this.getCache<Dictionary[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<Dictionary>(TABLE_NAME, params);
      this.setCache(cacheKey, result.data);
      return result.data;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchDictionaryTerm(slug: string, params: QueryMany<DictionaryApiResult> = {}) {
    const cacheKey = `${TABLE_NAME}-${slug}`;
    const cache = this.getCache<Dictionary>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getSingleItem<DictionaryApiResult>(TABLE_NAME, {
        ...params,
        fields: '*,related_terms.other_term_id.*',
        filter: { slug: { _eq: slug } },
      });
      const simplifiedTerm = filterByStatusDeepSingle(simplifyRelatedTermsStructure(result));
      this.setCache(cacheKey, simplifiedTerm);
      return simplifiedTerm;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchDictionaryTermId(id: string) {
    const cacheKey = `${TABLE_NAME}-${id}`;
    const cache = this.getCache<Dictionary>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemById<DictionaryApiResult>(TABLE_NAME, id, {
        fields: '*, related_terms.other_term_id.*',
      });
      const simplifiedTerm = filterByStatusDeepSingle(simplifyRelatedTermsStructure(result));
      this.setCache(cacheKey, simplifiedTerm);
      return simplifiedTerm;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
