import { Product } from '@/models/product';
import { filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'products';

export class ProductService extends BaseDirectusService {
  static async fetchProduct(slug: string, skipCache = false): Promise<Product> {
    const cacheKey = `${TABLE_NAME}-single-${slug}`;
    const cached = this.getCache<Product>(cacheKey);
    if (cached && !skipCache) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<Product>(TABLE_NAME, {
        fields:
          '*.*.*, faqs.faq_id.*, features.features_id.*, docs.documentation_id.*.*, product_sections.product_sections_id.*',
        filter: { slug: { _eq: slug } },
      });
      if (!result || result.data.length === 0) {
        throw new Error(`Product with slug: ${slug} was not found`);
      }
      // @ts-expect-error TODO fix type
      const product = filterByStatusDeepSingle(result.data[0]);

      if (!skipCache) {
        this.setCache(cacheKey, product);
      }
      // @ts-expect-error TODO fix type
      return product;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
