/**
 * TODO check where is this used, identical with following file:
 * https://github.com/localazy/localazy-components/blob/0a997dc500467d9784b1415284b306f21ca8d4a5/src/modules/common/config/date-format.ts#L1-L7
 */

export const locale = 'en-GB';

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
