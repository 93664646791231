import { AddUserReward } from '@/modules/ambassador/models/add-user-reward';
import { UserRewardApiResult } from '@/modules/ambassador/models/user-reward-api-result';

import { IApiResult } from '@/modules/api/models/i-api-result';
import { ApiServiceBase } from '@/modules/api/services/api-service-base';

/** https://localazy.fibery.io/GIST/Idea/357/Ambassador-technical-description-1943 */

export class UserRewardsBaseService extends ApiServiceBase {
  static async fetchUserRewards(): Promise<UserRewardApiResult> {
    try {
      this.throwErrorIfAxiosInstanceNotSet();

      const result = await this.axiosInstance.get('/user/rewards');
      return result.data;
    } catch (e: any) {
      throw e.data || e;
    }
  }

  static async addUserRewardGeneric<RewardType>(payload: AddUserReward<RewardType>): Promise<IApiResult> {
    try {
      this.throwErrorIfAxiosInstanceNotSet();

      const link = `/user/rewards/add/${String(payload.type)}`;
      const result = payload.verification
        ? await this.axiosInstance.post(link, { verification: payload.verification })
        : await this.axiosInstance.get(link);
      return result.data;
    } catch (e: any) {
      throw e.data || e;
    }
  }
}
