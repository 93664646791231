import { AnalyticsService } from '@/modules/analytics/services/analytics-service';
import {
  ListViewOptions,
  ProductTracking,
  CheckoutOptions,
  FilteredListViewOptions,
  StripeProductTracking,
  StripePriceTracking,
} from '@/modules/analytics/models/e-commerce-model';
import {
  EcommerceCheckoutEventData,
  EcommerceListEventData,
  EcommerceProductEventData,
} from '@/modules/analytics/models/track-event-data';

export class EcommerceAnalyticsService {
  public static trackListViewedEvent(payload: ListViewOptions & EcommerceListEventData) {
    AnalyticsService.trackEcommerceEvent('Product List Viewed', payload);
  }

  public static trackListFilteredEvent(payload: FilteredListViewOptions & EcommerceListEventData) {
    AnalyticsService.trackEcommerceEvent('Product List Filtered', payload);
  }

  public static trackProductClicked(payload: ProductTracking & EcommerceProductEventData) {
    AnalyticsService.trackEcommerceEvent('Product Clicked', payload);
  }

  public static trackProductViewed(payload: ProductTracking & EcommerceProductEventData) {
    AnalyticsService.trackEcommerceEvent('Product Viewed', payload);
  }

  public static trackProductAdded(payload: ProductTracking & EcommerceProductEventData) {
    AnalyticsService.trackEcommerceEvent('Product Added', payload);
  }

  public static trackCheckoutStarted(checkout: CheckoutOptions & EcommerceCheckoutEventData, product: ProductTracking) {
    AnalyticsService.trackEcommerceEvent('Checkout Started', {
      checkout,
      products: [product],
    });
  }

  public static trackOrderCompleted(payload: ProductTracking & EcommerceCheckoutEventData) {
    AnalyticsService.trackEcommerceEvent('Order Completed', payload);
  }

  public static buildTrackingFromStripeProduct(payload: StripeProductTracking): ProductTracking[] {
    let pricesInInterval = payload.product.prices;
    if (payload.interval) {
      pricesInInterval = pricesInInterval.filter((price: any) => price.interval === payload.interval);
    }
    return pricesInInterval.map((price: any) =>
      EcommerceAnalyticsService.buildTrackingFromStripePrice({
        name: payload.name,
        price,
        list_id: payload.list_id,
        category: payload.category,
      }),
    );
  }

  public static buildTrackingFromStripePrice(payload: StripePriceTracking): ProductTracking {
    const { price, name, category, list_id } = payload;
    return {
      product_id: price.id,
      name: typeof name === 'string' ? name : name(price),
      category,
      list_id,
      price: this.calculatePrice({ price, inDollars: true }),
      paymentType: price.type,
      paymentInterval: price.interval,
    };
  }

  protected static calculatePrice(data: { price: any; discount?: number; inDollars?: boolean }): number {
    const discount = data.discount ?? 0;
    let finalPrice = data.price.unitAmount - discount;
    if (data.inDollars) {
      finalPrice /= 100;
    }
    return finalPrice;
  }
}
