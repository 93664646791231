import { QueryMany } from '@directus/sdk';
import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';
import { Brand } from '@/models/brand';

const TABLE_NAME = 'brands';

export class FeaturedBrandsService extends BaseDirectusService {
  static async fetchAllFeaturedBrands(params: QueryMany<Brand> = {}) {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<Brand[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<Brand>(TABLE_NAME, {
        ...params,
        fields: '*.*',
        filter: { featured: { _eq: true } },
      });
      const featured = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, featured);
      return featured;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
