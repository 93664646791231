import { QueryMany } from '@directus/sdk';
import { merge } from 'lodash-es';
import { BaseDirectusService } from '@/services/base-directus-service';
import { DocSection, DocSectionApiResult } from '@/models/doc-section';
import {
  DocumenationDetail,
  DocumentationPreview,
  DocumentationPreviewApiResult,
  DocumenationDetailApiResult,
} from '@/models/documentation';
import {
  filterByStatusDeepSingle,
  filterByStatusDeepArray,
  hasRightStatus,
} from '@/util-services/filter-by-status-service';
import { DocSubsection } from '@/models/doc-subsection';
import { QueryOptions } from '@/models/query-options';
import { BlogPost } from '@/models/ghost/blog-post';

const TABLE_NAME = 'documentation';
const SECTION_TABLE_NAME = 'docs_section';
const SUB_SECTION_TABLE_NAME = 'docs_subsection';

const simplifyDocPreview = (doc: DocumentationPreviewApiResult) => {
  const cp: DocumentationPreview = {
    ...doc,
    dictionary: doc.dictionary?.map((entry) => entry.dictionary_id) || [],
    faq: doc.faq?.map((entry) => entry.faq_id) || [],
    blog_articles: doc.blog_articles?.map((entry) => entry.ghost_articles_id) || [],
  };
  return cp;
};

const simplifyDocDetail = (doc: DocumenationDetailApiResult) => {
  const cp: DocumenationDetail = {
    ...doc,
    dictionary: doc.dictionary.map((entry) => entry.dictionary_id),
    faq: doc.faq.map((entry) => entry.faq_id),
    blog_articles: doc.blog_articles
      .filter(({ ghost_articles_id }) => ghost_articles_id !== undefined)
      .map(
        ({ ghost_articles_id }) =>
          ({
            id: ghost_articles_id.id,
            owner: {
              id: ghost_articles_id.author.id,
              first_name: ghost_articles_id.author.name,
              slug: ghost_articles_id.author.slug,
              last_name: '',
              avatar: ghost_articles_id.author.profile_image,
            },
            created_by: {
              id: ghost_articles_id.author.id,
              first_name: ghost_articles_id.author.name,
              slug: ghost_articles_id.author.slug,
              last_name: '',
              avatar: ghost_articles_id.author.profile_image,
            },
            sort: ghost_articles_id.sort,
            title: ghost_articles_id.title,
            slug: ghost_articles_id.slug,
            modified_on: ghost_articles_id.date_updated,
            created_on: ghost_articles_id.date_created,
            pinned: ghost_articles_id.featured,
            badge: null,
            priority: '0.7',
            main_image: ghost_articles_id.feature_image,
            status: ghost_articles_id.status,
            reading_time: 0,
            og_title: ghost_articles_id.meta_title,
            og_description: ghost_articles_id.meta_description,
            og_image: ghost_articles_id.feature_image,
            tags: ghost_articles_id.tags?.map((tag) => tag.tag_id) || [],
            dictionary: [],
          }) as BlogPost,
      ),
  };
  return cp;
};

const simplifySectionStructure = (section: DocSectionApiResult) => {
  const cp: DocSection = {
    ...section,
    blog_tags: section.blog_tags.map((entry) => entry.tag_id),
  };
  return cp;
};

export class DocsService extends BaseDirectusService {
  static async fetchDocs(
    params: QueryMany<DocumentationPreviewApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<DocumentationPreview[]> {
    const cacheKey = `${TABLE_NAME}-${JSON.stringify(params)}`;
    const cached = this.getCache<DocumentationPreview[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<DocumentationPreviewApiResult>(
        TABLE_NAME,
        {
          fields: this.fieldsForDocumentationPreview(),
          sort: ['-pinned', 'sort', '-modified_on', '-created_on'],
          ...params,
        },
        queryOptions,
      );
      const docs = filterByStatusDeepArray(result.data.map((doc) => simplifyDocPreview(doc)));
      this.setCache(cacheKey, docs);
      return docs;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchPinnedDocs(
    params: QueryMany<DocumentationPreviewApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<DocumentationPreview[]> {
    const cacheKey = `${TABLE_NAME}-pinned-${JSON.stringify(params)}`;
    const cached = this.getCache<DocumentationPreview[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<DocumentationPreviewApiResult>(
        TABLE_NAME,
        {
          ...params,
          filter: { pinned: { _eq: true } },
          fields: this.fieldsForDocumentationPreview(),
          sort: ['-pinned', 'sort', '-modified_on', '-created_on'],
        },
        queryOptions,
      );
      const docs = filterByStatusDeepArray(result.data.map((doc) => simplifyDocPreview(doc)));
      this.setCache(cacheKey, docs);
      return docs;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchDocsInSection(
    section: string,
    params: QueryMany<DocumentationPreviewApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<DocumentationPreview[]> {
    const cacheKey = `${TABLE_NAME}-section-${section}-${JSON.stringify(params)}`;
    const cached = this.getCache<DocumentationPreview[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<DocumentationPreviewApiResult>(
        TABLE_NAME,
        {
          filter: { section: { slug: { _eq: section } } },
          fields: this.fieldsForDocumentationPreview(),
          sort: ['-pinned', 'sort', '-modified_on', '-created_on'],
          ...params,
        },
        queryOptions,
      );
      const docs = filterByStatusDeepArray(result.data.map((doc) => simplifyDocPreview(doc)));
      this.setCache(cacheKey, docs);
      return docs;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchDoc(
    slug: string,
    params: QueryMany<DocumenationDetailApiResult> = {},
    queryOptions: QueryOptions = {},
  ): Promise<DocumenationDetail> {
    const cacheKey = `${TABLE_NAME}-docs-${slug}`;
    const cached = this.getCache<DocumenationDetail>(cacheKey);
    if (cached) {
      return cached;
    }
    const requestParams = merge({ fields: this.fieldsForDocumentationDetail() }, params, {
      filter: { slug: { _eq: slug } },
    });
    try {
      const result = await this.getItemsWithStatus<DocumenationDetailApiResult>(
        TABLE_NAME,
        requestParams,
        queryOptions,
      );
      if (result.data.length === 0) {
        throw new Error(`Post with slug: ${slug} was not found `);
      }
      // @ts-expect-error TODO fix type
      const post = filterByStatusDeepSingle(simplifyDocDetail(result.data[0]));
      if (!hasRightStatus(post.section) || !hasRightStatus(post.subsection)) {
        throw new Error(`Post with slug: ${slug} has unpublished section`);
      }
      this.setCache(cacheKey, post);
      return post;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchSection(slug: string, queryOptions: QueryOptions = {}): Promise<DocSection> {
    const cacheKey = `${SECTION_TABLE_NAME}-sections-${slug}`;
    const cached = this.getCache<DocSection>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const result = await this.getSingleItem<DocSectionApiResult>(
        SECTION_TABLE_NAME,
        {
          filter: { slug: { _eq: slug } },
        },
        queryOptions,
      );
      const section = filterByStatusDeepSingle(simplifySectionStructure(result));
      this.setCache(cacheKey, section);
      return section;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchAllSections(queryOptions: QueryOptions = {}): Promise<DocSection[]> {
    const cacheKey = `${SECTION_TABLE_NAME}-sections`;
    const cached = this.getCache<DocSection[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const result = await this.getItemsWithStatus<DocSectionApiResult>(
        SECTION_TABLE_NAME,
        {
          fields: '*.*.*',
          sort: ['sort'],
        },
        queryOptions,
      );
      const sections = filterByStatusDeepArray(result.data.map((section) => simplifySectionStructure(section)));
      this.setCache(cacheKey, sections);
      return sections;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchSubSections(
    params: QueryMany<DocSubsection> = {},
    queryOptions: QueryOptions = {},
  ): Promise<DocSubsection[]> {
    const cacheKey = `${SUB_SECTION_TABLE_NAME}-${JSON.stringify(params)}`;
    const cached = this.getCache<DocSubsection[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const result = await this.getItemsWithStatus<DocSubsection>(
        SUB_SECTION_TABLE_NAME,
        {
          sort: ['sort'],
          ...params,
        },
        queryOptions,
      );
      const sections = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, sections);
      return sections;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchFirstDocInSection(slug: string, queryOptions: QueryOptions = {}): Promise<DocumentationPreview> {
    const cacheKey = `${SECTION_TABLE_NAME}-doc-first-${slug}`;
    const cached = this.getCache<DocumentationPreview>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const subsection = await DocsService.fetchSubSections(
        { limit: 1, sort: ['sort'], filter: { section: { slug: { _eq: slug } } } },
        queryOptions,
      );
      const doc = (
        await DocsService.fetchDocs(
          {
            limit: 1,
            fields: '*',
            sort: ['sort'],
            filter: {
              status: { _in: BaseDirectusService.resolveItemStatus() },
              section: { slug: { _eq: slug } },
              // @ts-expect-error TODO fix type
              subsection: { id: { _eq: subsection[0].id } },
            },
          },
          queryOptions,
        )
      )[0];
      this.setCache(cacheKey, doc);
      // @ts-expect-error TODO fix type
      return doc;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  private static fieldsForDocumentationDetail() {
    let fields: any = '*.*';
    fields += ' ,blog_articles.ghost_articles_id.id';
    fields += ' ,blog_articles.ghost_articles_id.status';
    fields += ' ,blog_articles.ghost_articles_id.slug';
    fields += ' ,blog_articles.ghost_articles_id.title';
    fields += ' ,blog_articles.ghost_articles_id.feature_image';
    fields += ' ,blog_articles.ghost_articles_id.sort';
    fields += ' ,blog_articles.ghost_articles_id.meta_title';
    fields += ' ,blog_articles.ghost_articles_id.meta_description';
    fields += ' ,blog_articles.ghost_articles_id.date_created';
    fields += ' ,blog_articles.ghost_articles_id.date_updated';

    fields += ' ,blog_articles.ghost_articles_id.tags.tag_id.*';
    fields += ' ,blog_articles.ghost_articles_id.author.*';
    fields += ', faq.*.*.*';
    fields += ', section.*.*';
    fields += ', subsection.*.*';
    fields += ', owner.*.*';
    fields += ', dictionary.*.*';
    return fields;
  }

  private static fieldsForDocumentationPreview() {
    let fields: any = '*.*';

    fields += ', faq.*.*';
    fields += ', section.*.*';
    fields += ', subsection.*.*';
    fields += ', owner.*.*';
    fields += ', dictionary.*.*';

    return fields;
  }
}
