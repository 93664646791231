import { ModeService } from '@/services/mode-service';
import { Item, ItemMetadata, LogicalFilterAnd, QueryMany, QueryOne } from '@directus/sdk';
import { cloneDeep, isArray, isObject, isPlainObject, merge, uniq } from 'lodash-es';
import { directusApi } from '@/api/directus-api';
import { CacheService } from '@/util-services/cache-service';
import { filterByStatus } from '@/util-services/filter-by-status-service';
import { ItemStatus } from '@/models/item-status';
import { DirectusCollections } from '@/models/directus-collections';
import { QueryOptions } from '@/models/query-options';
import { languageService } from '@/services/language-service';

type ItemsResult<T> = {
  data: T[];
  meta?: ItemMetadata;
};

export class BaseDirectusService {
  protected static resolveItemStatus(): ItemStatus[] {
    if (ModeService.isProduction()) {
      return ['published'];
    } else {
      return ['published', 'draft'];
    }
  }

  protected static getCache<T>(cacheKey: string): T | undefined {
    const cached = CacheService.get<T>(cacheKey);
    return cached;
  }

  protected static setCache(cacheKey: string, data: unknown): void {
    CacheService.set(cacheKey, data);
  }

  /**
   * This method can be used only with collections which have status field
   * Draft items aren't returned in production environment
   */
  static async getItemsWithStatus<T extends { status: ItemStatus }>(
    tableName: keyof DirectusCollections,
    params: QueryMany<T> = {},
    queryOptions: QueryOptions = {},
  ): Promise<ItemsResult<T>> {
    const result = await directusApi()
      .items(tableName)
      .readByQuery(
        // @ts-expect-error TODO fix type
        BaseDirectusService.resolveParams(
          queryOptions,
          {
            limit: -1,
            meta: '*',
            filter: {
              status: { _in: BaseDirectusService.resolveItemStatus() },
            },
          },
          params,
        ),
      );
    if (result.data) {
      return {
        ...result,
        data: result.data.map((item) =>
          BaseDirectusService.translateItem(item, queryOptions.language ?? languageService.language),
        ) as T[],
      };
    }
    throw Error(`Cannot retrieve ${tableName}`);
  }

  /**
   * Works with any collection, returns all items in query and their statuses
   * Filtering is done on client side or skipped if no status field is present
   * WARN: "limit" won't work properly in production if there are draft items in response
   */
  static async getItems<T extends Item>(
    tableName: keyof DirectusCollections,
    params: QueryMany<T> = {},
    queryOptions: QueryOptions = {},
  ): Promise<ItemsResult<T>> {
    const result = await directusApi()
      .items(tableName)
      .readByQuery(
        // @ts-expect-error TODO fix type
        BaseDirectusService.resolveParams(
          queryOptions,
          {
            limit: -1,
            meta: '*',
          },
          params,
        ),
      );
    if (result.data) {
      const filteredData = filterByStatus(result.data);

      return {
        data: filteredData.map((item) =>
          BaseDirectusService.translateItem(item, queryOptions.language ?? languageService.language),
        ),
        meta: { ...result.meta, filter_count: filteredData.length },
      } as ItemsResult<T>;
    }
    throw Error(`Cannot retrieve ${tableName}`);
  }

  static async getSingleItem<T extends { status: ItemStatus }>(
    tableName: keyof DirectusCollections,
    params: QueryMany<T> = {},
    queryOptions: QueryOptions = {},
  ): Promise<T> {
    const result = await directusApi()
      .items(tableName)
      .readByQuery(
        // @ts-expect-error TODO fix type
        BaseDirectusService.resolveParams(
          queryOptions,
          {
            limit: 1,
            filter: {
              status: { _in: BaseDirectusService.resolveItemStatus() },
            },
          },
          params,
        ),
      );

    if (Array.isArray(result.data) && result.data.length > 0) {
      return BaseDirectusService.translateItem(
        // @ts-expect-error TODO fix type
        result.data[0],
        queryOptions.language ?? languageService.language,
      ) as unknown as T;
    }

    /** Happens when collection in Directus is configured as singleton */
    if (isPlainObject(result.data)) {
      return BaseDirectusService.translateItem(
        // @ts-expect-error TODO fix type
        result.data,
        queryOptions.language ?? languageService.language,
      ) as unknown as T;
    }

    throw Error(`Cannot retrieve ${tableName}`);
  }

  static async getItemById<T extends Item>(tableName: string, id: string | number, params: QueryOne<T> = {}) {
    // @ts-expect-error TODO fix type
    const result = await directusApi().items(tableName).readOne(id, params);
    if (result) {
      return result as T;
    }
    throw Error(`Cannot retrieve ${tableName}`);
  }

  private static resolveParams<T extends Item>(
    queryOptions: QueryOptions = {},
    ...params: QueryMany<T>[]
  ): QueryMany<T> {
    const additionalPayload: any = {};
    const isLanguageFiltering = queryOptions.language ?? languageService.language !== '';
    const mergedParams: QueryMany<T> = merge(additionalPayload, ...params);

    if (isLanguageFiltering) {
      const targetLanguages = uniq([
        queryOptions.language ?? languageService.language,
        ...languageService.fallbackLanguages,
      ]);
      const filters: LogicalFilterAnd<any> = {
        _and: [
          {
            languages_code: {
              _in: targetLanguages,
            },
          },
        ],
      };

      additionalPayload.deep = {
        translations: {
          _filter: filters,
        },
      };
    }

    const isMissingLanguageField =
      !mergedParams.fields ||
      (!mergedParams.fields.toString().includes('*.*') && !mergedParams.fields.toString().includes('translations.*'));

    if (isLanguageFiltering && isMissingLanguageField) {
      if (Array.isArray(mergedParams.fields)) {
        mergedParams.fields = [...mergedParams.fields, 'translations.*'];
      }
      if (typeof mergedParams.fields === 'string') {
        mergedParams.fields = `${mergedParams.fields},translations.*` as any;
      }
    }
    return mergedParams;
  }

  private static translateItem<T extends Item>(item: T, language: string): T {
    // TODO fix type
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    let translation: any | undefined;

    const languageOptions = [language, ...languageService.fallbackLanguages];
    languageOptions.forEach((lang) => {
      if (!translation) {
        translation = item.translations?.find((t: any) => t.languages_code?.code === lang || t.languages_code === lang);
      }
    });

    const translatedItem = cloneDeep(item);

    if (translation) {
      // TODO fix type
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const translatableFields = Object.keys(translation).filter((key) => !['id', 'languages_code'].includes(key));
      translatableFields.forEach((field: keyof T) => {
        if (translation[field] !== null) {
          translatedItem[field] = translation[field];
        }
      });
    }

    Object.entries(item).forEach(([key, value]) => {
      if (isArray(value)) {
        translatedItem[key as keyof T] = value.map((i) => BaseDirectusService.translateItem(i, language)) as any;
      } else if (isObject(value)) {
        translatedItem[key as keyof T] = BaseDirectusService.translateItem(value, language) as any;
      }
    });
    return translatedItem;
  }
}
