import { QueryMany } from '@directus/sdk';
import { merge } from 'lodash-es';
import { CommunityArticleApiResult } from '@/models/community/community-article-api-result';
import { CommunityArticle } from '@/models/community/community-article';
import { CommunitySection } from '@/models/community/community-section';
import { filterByStatusDeepArray, filterByStatusDeepSingle } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'community';
const SECTION_TABLE_NAME = 'community_section';

const simplifyStructure = (article: CommunityArticleApiResult) => {
  const cp: CommunityArticle = {
    ...article,
    dictionary: article.dictionary.map((entry) => entry.dictionary_id),
    faq: article.faq.map((entry) => entry.faq_id),
  };
  return cp;
};

export class CommunityService extends BaseDirectusService {
  static async fetchAllArticles(params: QueryMany<CommunityArticleApiResult> = {}): Promise<CommunityArticle[]> {
    const cacheKey = `${TABLE_NAME}-all`;
    const cached = this.getCache<CommunityArticle[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<CommunityArticleApiResult>(TABLE_NAME, {
        ...params,
        fields: '*.*.*',
        sort: ['-pinned', 'sort', '-modified_on', '-created_on'],
      });
      const articles = filterByStatusDeepArray(result.data.map((article) => simplifyStructure(article)));
      this.setCache(cacheKey, articles);
      return articles;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchPinnedArticles(params: QueryMany<CommunityArticleApiResult> = {}): Promise<CommunityArticle[]> {
    const cacheKey = `${TABLE_NAME}-pinned`;
    const cached = this.getCache<CommunityArticle[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<CommunityArticleApiResult>(TABLE_NAME, {
        ...params,
        filter: { pinned: { _eq: true } },
        fields: '*.*.*',
        sort: ['-pinned', 'sort', '-modified_on', '-created_on'],
      });
      const articles = filterByStatusDeepArray(result.data.map((article) => simplifyStructure(article)));
      this.setCache(cacheKey, articles);
      return articles;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchArticlesInSection(
    section: string,
    params: QueryMany<CommunityArticleApiResult> = {},
  ): Promise<CommunityArticle[]> {
    const cacheKey = `${TABLE_NAME}-section-${section}-${JSON.stringify(params)}`;
    const cached = this.getCache<CommunityArticle[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItemsWithStatus<CommunityArticleApiResult>(
        TABLE_NAME,
        merge(
          {
            filter: { section: { slug: { _eq: section } } },
            fields: '*.*.*',
            sort: ['-pinned', 'sort', '-modified_on', '-created_on'],
          },
          params,
        ),
      );
      const articles = filterByStatusDeepArray(result.data.map((article) => simplifyStructure(article)));
      this.setCache(cacheKey, articles);
      return articles;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchArticle(slug: string): Promise<CommunityArticle> {
    const cacheKey = `${TABLE_NAME}-community-${slug}`;
    const cached = this.getCache<CommunityArticle>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const result = await this.getItemsWithStatus<CommunityArticleApiResult>(TABLE_NAME, {
        fields: '*.*.*',
        filter: { slug: { _eq: slug } },
      });
      if (result.data.length === 0) {
        throw new Error(`Article with slug: ${slug} was not found `);
      }
      // @ts-expect-error TODO fix type
      const post = filterByStatusDeepSingle(simplifyStructure(result.data[0]));
      this.setCache(cacheKey, post);
      return post;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchSection(slug: string): Promise<CommunitySection[]> {
    const cacheKey = `${SECTION_TABLE_NAME}-sections-${slug}`;
    const cached = this.getCache<CommunitySection[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const result = await this.getItems<CommunitySection>(SECTION_TABLE_NAME, {
        filter: { slug: { _eq: slug } },
      });
      if (result.data.length === 0) {
        throw new Error(`Community section with slug: ${slug} was not found `);
      }
      const section = result.data;
      this.setCache(cacheKey, section);
      return section;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchAllSections(): Promise<CommunitySection[]> {
    const cacheKey = `${SECTION_TABLE_NAME}-sections`;
    const cached = this.getCache<CommunitySection[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const result = await this.getItemsWithStatus<CommunitySection>(SECTION_TABLE_NAME, {
        fields: '*.*.*',
      });
      const sections = filterByStatusDeepArray(result.data);
      this.setCache(cacheKey, sections);
      return sections;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
