import { QueryMany } from '@directus/sdk';
import { buildCacheKey } from '@/functions/build-cache-key';
import { FaqCategory } from '@/models/faq-category';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'faq_category';

export class FaqCategoryService extends BaseDirectusService {
  static async fetchAllOnIndexFaqCategories(params: QueryMany<FaqCategory> = {}): Promise<FaqCategory[]> {
    const cacheKey = buildCacheKey(TABLE_NAME, params);
    const cache = this.getCache<FaqCategory[]>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getItems<FaqCategory>(TABLE_NAME, {
        fields: '*.*',
        filter: { on_faq_index: { _eq: true } },
        ...params,
      });
      this.setCache(cacheKey, result.data);
      return result.data;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
