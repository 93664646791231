import { QueryMany } from '@directus/sdk';
import { PageHighlight } from '@/models/page-highlight';
import { BaseDirectusService } from '@/services/base-directus-service';
import { randomChoice } from '@/util-services/random-choice';

const TABLE_NAME = 'various_pages_highlights';

export class PagesHighlightsService extends BaseDirectusService {
  static async fetchPageHighlight(name: string, params: QueryMany<PageHighlight> = {}): Promise<PageHighlight> {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<PageHighlight>(cacheKey);
    if (cache) {
      return cache;
    }
    try {
      const result = await this.getItemsWithStatus<PageHighlight>(TABLE_NAME, {
        fields: '*.*.*',
        filter: {
          page_name: { _eq: name },
        },
        ...params,
      });
      const pageHighlight = randomChoice(result.data);
      this.setCache(cacheKey, pageHighlight);
      return pageHighlight;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
