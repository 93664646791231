import { QueryMany } from '@directus/sdk';
import { ICTA } from '@/models/cta-model';
import { BaseDirectusService } from '@/services/base-directus-service';
import { buildCacheKey } from '@/functions/build-cache-key';

const TABLE_NAME = 'call_to_action';

export class CtaService extends BaseDirectusService {
  static async fetchAllCTA(params: QueryMany<ICTA> = {}) {
    const cacheKey = buildCacheKey(`${TABLE_NAME}-all`, params);
    const cache = this.getCache<ICTA[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<ICTA>(TABLE_NAME, {
        fields: '*',
        ...params,
      });
      this.setCache(cacheKey, result.data);
      return result.data;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchRandomCTA() {
    const allCTA = await CtaService.fetchAllCTA();
    return allCTA[Math.floor(Math.random() * allCTA.length)];
  }

  static async fetchCTAById(id: string) {
    const cacheKey = `${TABLE_NAME}-${id}`;
    const cache = this.getCache<ICTA>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemById<ICTA>(TABLE_NAME, id, {
        fields: '*',
      });
      this.setCache(cacheKey, result);
      return result;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
