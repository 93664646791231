import { DirectusFeaturedAction } from '@/models/community/directus-featured-action';
import { EarnPointsCommunityAction } from '@/models/community/earn-points-community-action';
import { ShareLinkCommunityAction } from '@/models/community/share-link-community-action';
import { BaseDirectusService } from '@/services/base-directus-service';
import { QueryOptions } from '@/models/query-options';
import { DirectusBlogService } from '@/services/directus-blog-service';
import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';

const TABLE_NAME_ACTIONS = 'community_actions';

export class CommunityActionsService extends BaseDirectusService {
  static async fetchActions(skipCache = true, queryOptions: QueryOptions = {}): Promise<DirectusFeaturedAction[]> {
    const cacheKey = 'fetch_actions';
    const cached = this.getCache<DirectusFeaturedAction[]>(cacheKey);
    if (cached && !skipCache) {
      return cached;
    }

    try {
      const result = await this.getItems<DirectusFeaturedAction>(
        TABLE_NAME_ACTIONS,
        {
          fields: '*.*.*',
        },
        queryOptions,
      );
      return result.data;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchShareableActions(queryOptions: QueryOptions = {}): Promise<ShareLinkCommunityAction[]> {
    try {
      const shareableBlogPosts = await DirectusBlogService.fetchArticles(
        {
          filter: {
            internal_tags: {
              // @ts-expect-error TODO fix type
              ghost_internal_tags_id: {
                label: {
                  _eq: '[COMSHARE]',
                },
              },
            },
          },
        },
        queryOptions,
      );
      const publishedPosts = filterByStatusDeepArray(shareableBlogPosts);
      return publishedPosts.map((post) => ({
        id: post.id,
        slug: post.slug,
        type: 'share',
        title: post.title,
        description: post.meta_description || post.title,
        image: post.feature_image,
        link: `/blog/${post.slug}`,
        sort: post.sort,
        date_created: post.date_created,
      }));
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  static async fetchEarnPointsActions(queryOptions: QueryOptions = {}): Promise<EarnPointsCommunityAction[]> {
    try {
      const result = await this.getItems<DirectusFeaturedAction>(
        TABLE_NAME_ACTIONS,
        {
          fields: '*.*.*',
          filter: {
            points_reward: {
              // @ts-expect-error TODO fix type
              _neq: null,
            },
          },
        },
        queryOptions,
      );

      const publishedActions = filterByStatusDeepArray(result.data);
      return publishedActions.map(
        (action) =>
          ({
            id: action.id,
            slug: action.slug,
            type: 'earn-points',
            title: action.title,
            description: action.content,
            points_reward: action.points_reward,
            href: action.link,
            sort: action.sort,
            created_on: action.created_on,
          }) as EarnPointsCommunityAction,
      );
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
