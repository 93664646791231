import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const createApi = (baseURL: string, options: AxiosRequestConfig = {}) =>
  axios.create({
    // Must be relative path in development, otherwise devProxy won't work
    // See https://github.com/vuejs/vue-cli/issues/3423
    baseURL,
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      ...(options.headers ?? {}),
    },
  });

export function apiFactory(baseURL: string, options: AxiosRequestConfig = {}): AxiosInstance {
  const request = createApi(baseURL, options);

  // response interceptors
  request.interceptors.response.use(
    (response) => response, // Do something with response data
    (error: unknown) => {
      // Do something with response error
      return Promise.reject(error instanceof Error ? error : new Error(String(error)));
    },
  );

  return request;
}
