export class ModeService {
  protected static mode: string = 'production';

  public static updateMode(mode: string) {
    ModeService.mode = mode;
  }

  public static isProduction(): boolean {
    return ModeService.mode === 'production';
  }
}
