import { QueryMany } from '@directus/sdk';
import { PricingPlanFeature } from '@/models/pricing-plan-feature';
import { QueryOptions } from '@/models/query-options';
import { BaseDirectusService } from '@/services/base-directus-service';

const TABLE_NAME = 'pricing_plans_features';

export class PricingPlansFeaturesService extends BaseDirectusService {
  static async fetchAllPlansFeatures(params: QueryMany<PricingPlanFeature> = {}, queryOptions: QueryOptions = {}) {
    const cacheKey = `${TABLE_NAME}`;
    const cache = this.getCache<PricingPlanFeature[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.getItemsWithStatus<PricingPlanFeature>(
        TABLE_NAME,
        {
          fields: '*.*',
          ...params,
        },
        queryOptions,
      );
      this.setCache(cacheKey, result.data);
      return result.data;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}
