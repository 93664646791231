import { filterByStatusDeepArray } from '@/util-services/filter-by-status-service';
import { BaseDirectusService } from '@/services/base-directus-service';
import { CaseStudy, CaseStudyDetailApiResult } from '@/models/case-study';

const TABLE_NAME = 'case_study';

const simplifyStructure = (caseStudy: CaseStudyDetailApiResult) => {
  const cp: CaseStudy = {
    ...caseStudy,
    brand: {
      ...caseStudy.brand,
      industries: caseStudy.brand.industries.map((entry) => entry.industries_id),
    },
    features: caseStudy.features.map((entry) => entry.features_id),
    integrations: caseStudy.integrations.map((entry) => entry.platforms_id),
    blog_articles: caseStudy.blog_articles.map((entry) => entry.ghost_articles_id),
    roles: caseStudy.roles.map((entry) => entry.personas_id),
    industries: caseStudy.industries.map((entry) => entry.personas_id),
    client_industry: caseStudy.client_industry.map((entry) => entry.industries_translations_id),
  };
  return cp;
};

export class CaseStudyService extends BaseDirectusService {
  static async fetchCaseStudies(): Promise<CaseStudy[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cached = this.getCache<CaseStudy[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItems<CaseStudyDetailApiResult>(TABLE_NAME, {
        fields: ['*.*.*', 'brand.industries.*.*.*'],
      });
      const caseStudies = filterByStatusDeepArray(result.data.map((p) => simplifyStructure(p)));
      this.setCache(cacheKey, caseStudies);
      return caseStudies;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }

  static async fetchShareableCaseStudies(): Promise<CaseStudy[]> {
    const cacheKey = `${TABLE_NAME}-shareable`;
    const cached = this.getCache<CaseStudy[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const result = await this.getItems<CaseStudyDetailApiResult>(TABLE_NAME, {
        fields: '*.*.*',
        filter: {
          internal_tag: {
            label: {
              _eq: '[COMSHARE]',
            },
          },
        },
      });
      const caseStudies = filterByStatusDeepArray(result.data.map((p) => simplifyStructure(p)));
      this.setCache(cacheKey, caseStudies);
      return caseStudies;
    } catch (err: unknown) {
      if (err && typeof err === 'object' && 'data' in err) {
        throw err.data;
      }

      throw err;
    }
  }
}
